import React, { useEffect, useState } from "react";
import style from "./gameTree.module.css";
import AnimatedWheel from "./AnimatedWheel";
import Navigate from "../../components/navigate";
import { decrement, increment } from "../../store/counter/counter";
import useSound from "../../utils/soundUtils";
import { useDispatch, useSelector } from "react-redux";

import runweel from "../../songs/runweel.mp3";
import bets from "../../songs/bet.mp3";
import win from "../../songs/win.mp3";

import { useNavigate } from "react-router-dom";

export default function GameTree() {
  const [spinStart, setSpinStart] = useState(false);
  const [spinResult, setSpinResult] = useState(null);
  const [bet, setBet] = useState(50);

  const [winCount, setWinCounter] = useState(0);

  const [freezeButton, setFreezeButton] = useState(false);

  const dispatch = useDispatch();

  const { playSound } = useSound();

  const navigate = useNavigate();

  const counter = useSelector((state) => state.counter.count);

  useEffect(() => {
    const handlePopState = (event) => {
      event.preventDefault();
      navigate(0); // Оставляет пользователя на текущей странице
    };

    window.history.pushState(null, "", window.location.href);
    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [navigate]);

  const plusBet = () => {
    playSound(bets);

    setBet((prev) => {
      const newBet =
        prev < counter ? (prev + 50 < counter ? prev + 50 : counter) : counter;
      return newBet;
    });
  };

  const minusBet = () => {
    playSound(bets);
    setBet((prev) => {
      const newBet = prev - 50;

      if (newBet < 0) {
        return 0;
      }

      return newBet;
    });
  };

  const startSpinWeel = () => {
    if (bet > 0 && counter >= bet && !freezeButton) {
      setFreezeButton(true);
      playSound(runweel);
      dispatch(decrement(bet));
      setSpinStart(true);
    }
  };

  useEffect(() => {
    if (spinResult) {
      let count = 0;
      switch (spinResult) {
        case "x1.5":
          count = 1;
          break;
        case "x2":
          count = 2;
          break;
        case "200":
          setWinCounter(200);
          break;
        case "400":
          setWinCounter(400);
          break;
        case "600":
          setWinCounter(600);
          break;
        case "800":
          setWinCounter(800);
          break;
        case "5000":
          setWinCounter(5000);
          break;
        default:
          break;
      }
      let res = bet * count;

      console.log("res", res);

      if (res > 0) {
        setWinCounter(res);
      }

      setSpinResult(null);
    }
  }, [spinResult]);

  useEffect(() => {
    if (winCount > 0) {
      setTimeout(() => {
        playSound(win);

        const page = "gameTree";
        navigate(`/results/${winCount}/${page}`);

        dispatch(increment(winCount));
      }, 1500);
      setTimeout(() => {
        setWinCounter(0);
      }, 2000);
    }
  }, [winCount]);

  return (
    <div className={style.gameTree}>
      <div className={style.gameTreeBlock}>
        <div className={style.navBlock}>
          <Navigate lastPage="gameTree" startSpin={spinStart} />
        </div>
        <div className={style.gameBlock}>
          <div className={style.game}>
            <div className={style.weelArrowBlock}>
              <img src="/assets/rim.png" alt="" className={style.imgRimStyle} />
              <div className={style.arrowBlock}>
                <img
                  src="/assets/arrow.png"
                  alt=""
                  className={style.imgArrowStyle}
                />
              </div>
            </div>
            <div className={style.weelBlock}>
              <AnimatedWheel
                spinStart={spinStart}
                setSpinStart={setSpinStart}
                setSpinResult={setSpinResult}
                setFreezeButton={setFreezeButton}
              />
            </div>
          </div>
        </div>
        <div className={style.navigateComponent}>
          <div className={style.navigateBlock}>
            <div className={style.countBlock}>
              <div className={style.countFrame}>
                <img
                  src="/assets/frameForCount.png"
                  alt=""
                  className={style.imgFrameForCountStyle}
                />
                <div className={style.countDataBlock}>
                  <div className={style.textBlock}>
                    <span className={style.textStyle}>BALANCE</span>
                    <span className={style.textStyleTwo}>{counter}</span>
                  </div>
                  <div className={style.textBlock}>
                    <span className={style.textStyle}>WIN</span>
                    <span className={style.textStyleTree}>{winCount}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className={style.spinBlock}>
              <img
                src="/assets/buttonSpin.png"
                alt=""
                className={style.imgButtonSpinStyle}
                onClick={startSpinWeel}
              />
            </div>
            <div className={style.betBlock}>
              <div className={style.navBet}>
                <div>
                  <img
                    src="/assets/buttonMinus.png"
                    alt=""
                    className={style.imgButtonNavStyle}
                    onClick={minusBet}
                  />
                </div>
                <div>
                  <img
                    src="/assets/buttonPlus.png"
                    alt=""
                    className={style.imgButtonNavStyle}
                    onClick={plusBet}
                  />
                </div>
              </div>
              <div className={style.betFrame}>
                <img
                  src="/assets/frameForBet.png"
                  alt=""
                  className={style.imgFrameForCountStyle}
                />
                <div className={style.textBetBlock}>
                  <span className={style.textStyleFour}>{bet}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
