import React, { useEffect, useState } from "react";
import style from "./gameFour.module.css";
import FlippableCard from "./flippableCard";
import Navigate from "../../components/navigate";
import { useDispatch, useSelector } from "react-redux";
import useSound from "../../utils/soundUtils";
import { useNavigate } from "react-router-dom";

import win from "../../songs/win.mp3";
import lose from "../../songs/lose.mp3";

import spin from "../../songs/spin.mp3";
import bets from "../../songs/bet.mp3";
import { decrement, increment } from "../../store/counter/counter";

const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

export default function GameFour() {
  const [randomCards, setRandomCards] = useState([]);
  const [startGame, setStartGame] = useState(false);

  const [bet, setBet] = useState(50);

  const [winCount, setWinCounter] = useState(0);

  const [imageId, setImageId] = useState(0);

  const [freezeButton, setFreezeButton] = useState(false);

  const dispatch = useDispatch();

  const { playSound } = useSound();

  const navigate = useNavigate();

  const counter = useSelector((state) => state.counter.count);

  useEffect(() => {
    const handlePopState = (event) => {
      event.preventDefault();
      navigate(0); // Оставляет пользователя на текущей странице
    };

    window.history.pushState(null, "", window.location.href);
    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [navigate]);

  useEffect(() => {
    resetGame();
  }, []);

  const resetGame = () => {
    const newCards = shuffleArray([
      { id: 1, front: "/assets/x0.png", back: "/assets/back.png" },
      { id: 2, front: "/assets/x1.png", back: "/assets/back.png" },
      { id: 3, front: "/assets/x3.png", back: "/assets/back.png" },
      { id: 4, front: "/assets/x5.png", back: "/assets/back.png" },
    ]);

    setRandomCards(newCards);
  };

  const plusBet = () => {
    playSound(bets);

    setBet((prev) => {
      const newBet =
        prev < counter ? (prev + 50 < counter ? prev + 50 : counter) : counter;
      return newBet;
    });
  };

  const minusBet = () => {
    playSound(bets);
    setBet((prev) => {
      const newBet = prev - 50;

      if (newBet < 0) {
        return 0;
      }

      return newBet;
    });
  };

  const startSpin = () => {
    if (bet > 0 && counter >= bet && !freezeButton) {
      playSound(spin);
      dispatch(decrement(bet));
      setStartGame(true);
      setFreezeButton(true);
    }
  };

  useEffect(() => {
    if (imageId !== 0) {
      let count = 0;
      switch (imageId) {
        case 1:
          count = 0;
          break;
        case 2:
          count = 1;
          break;
        case 3:
          count = 3;
          break;
        case 4:
          count = 5;
          break;
        default:
          break;
      }
      let res = bet * count;

      setWinCounter(res);

      const page = "gameFour";

      if (res > 0) {
        setTimeout(() => {
          playSound(win);
          setFreezeButton(false);

          navigate(`/results/${res}/${page}`);
        }, 1000);
      } else {
        setTimeout(() => {
          playSound(lose);
          setFreezeButton(false);

          navigate(`/results/${0}/${page}`);
        }, 1000);
      }
    }
  }, [imageId]);

  useEffect(() => {
    if (winCount > 0) {
      setTimeout(() => {
        dispatch(increment(winCount));
      }, 1500);
      setTimeout(() => {
        setWinCounter(0);
      }, 2000);
    }
  }, [winCount]);

  return (
    <div className={style.gameFour}>
      <div className={style.gameFourBlock}>
        <div className={style.navBlock}>
          <Navigate lastPage="gameFour" startSpin={startGame} />
        </div>
        <div className={style.gameBlock}>
          <div className={style.game}>
            <img
              src="/assets/frameForGames.png"
              alt=""
              className={style.imgFrameForGamesStyle}
            />
            <div className={style.cardBlock}>
              <div className={style.cards}>
                {randomCards?.map((item) => (
                  <FlippableCard
                    key={item.id}
                    id={item.id}
                    frontImage={item.front}
                    backImage={item.back}
                    startGame={startGame}
                    setStartGame={setStartGame}
                    setImageId={setImageId}
                    setFreezeButton={setFreezeButton}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className={style.navigateBlock}>
          <div className={style.countBlock}>
            <div className={style.countFrame}>
              <img
                src="/assets/frameForCount.png"
                alt=""
                className={style.imgFrameForCountStyle}
              />
              <div className={style.countDataBlock}>
                <div className={style.textBlock}>
                  <span className={style.textStyle}>BALANCE</span>
                  <span className={style.textStyleTwo}>{counter}</span>
                </div>
                <div className={style.textBlock}>
                  <span className={style.textStyle}>WIN</span>
                  <span className={style.textStyleTree}>{winCount}</span>
                </div>
              </div>
            </div>
          </div>
          <div className={style.spinBlock}>
            <img
              src="/assets/buttonSpin.png"
              alt=""
              className={style.imgButtonSpinStyle}
              onClick={startSpin}
            />
          </div>
          <div className={style.betBlock}>
            <div className={style.navBet}>
              <div>
                <img
                  src="/assets/buttonMinus.png"
                  alt=""
                  className={style.imgButtonNavStyle}
                  onClick={minusBet}
                />
              </div>
              <div>
                <img
                  src="/assets/buttonPlus.png"
                  alt=""
                  className={style.imgButtonNavStyle}
                  onClick={plusBet}
                />
              </div>
            </div>
            <div className={style.betFrame}>
              <img
                src="/assets/frameForBet.png"
                alt=""
                className={style.imgFrameForCountStyle}
              />
              <div className={style.textBetBlock}>
                <span className={style.textStyleFour}>{bet}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
