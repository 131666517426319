// store.ts
import { configureStore } from "@reduxjs/toolkit";
import { effectsSlice } from "./sounds/sounds";
import { counterSlice } from "./counter/counter";

const store = configureStore({
  reducer: {
    effects: effectsSlice.reducer,
    counter: counterSlice.reducer,
  },
});

export default store;
