import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import style from "./slotMachine.module.css";

import win from "../../songs/win.mp3";
import lose from "../../songs/lose.mp3";
import useSound from "../../utils/soundUtils";
import { useNavigate } from "react-router-dom";

const frames = [
  {
    img: "/assets/bottle.png",
    alt: "bottle",
    id: 1,
  },

  {
    img: "/assets/pearls.png",
    alt: "pearls",
    id: 2,
  },

  {
    img: "/assets/shell.png",
    alt: "shell",
    id: 3,
  },
  {
    img: "/assets/peny.png",
    alt: "peny",
    id: 4,
  },

  {
    img: "/assets/fish.png",
    alt: "fish",
    id: 5,
  },

  {
    img: "/assets/star.png",
    alt: "seven",
    id: 6,
  },
  {
    img: "/assets/diamond.png",
    alt: "diamond",
    id: 7,
  },
  {
    img: "/assets/medusa.png",
    alt: "medusa",
    id: 8,
  },
  {
    img: "/assets/anchor.png",
    alt: "anchor",
    id: 9,
  },
];

const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

const SlotMachine = ({
  startSpin,
  setStartSpin,
  bet,
  setWinCounter,
  setFreezeButton,
}) => {
  const [slots, setSlots] = useState([[], [], []]);
  const [spinKeys, setSpinKeys] = useState([0, 1, 2]);
  const [activeSlot, setActiveSlot] = useState(-1);

  const [lines, setLines] = useState([]);
  const [hideLine, setHideLine] = useState(false);

  const [countResultLine, setCountResultLine] = useState([]);
  const [countResultDiagonal, setCountResultDiagonal] = useState([]);

  const [gameResult, setGameResult] = useState(null);

  const increaseWinProbability = 0.6;

  const { playSound } = useSound();

  const navigate = useNavigate();

  useEffect(() => {
    resetGame();
  }, []);

  const checkWin = (result) => {
    const { slot1, slot2, slot3 } = result;
    let win = false;
    const newLines = [];

    let rows = [];
    let diagonal = [];

    let countLine = [];
    let countDiagonal = [];

    let resWin = [];

    // Check rows
    for (let i = 0; i < 3; i++) {
      if (slot1[i].id === slot2[i].id && slot2[i].id === slot3[i].id) {
        win = true;
        rows.push(slot1[i].id);
        newLines.push({ type: "row", index: i });

        let res = {
          slot1: {
            id: slot1[i].id,
            index: i,
          },
          slot2: {
            id: slot2[i].id,
            index: i,
          },
          slot3: {
            id: slot3[i].id,
            index: i,
          },
        };

        countLine.push(res);
      }
    }

    setCountResultLine(countLine);

    // Check diagonals
    if (slot1[0].id === slot2[1].id && slot2[1].id === slot3[2].id) {
      win = true;
      diagonal.push(slot1[0].id);

      newLines.push({ type: "diagonal", index: 0 });

      let res = {
        slot1: {
          id: slot1[0].id,
          index: 0,
        },
        slot2: {
          id: slot2[1].id,
          index: 1,
        },
        slot3: {
          id: slot3[2].id,
          index: 2,
        },
      };

      countDiagonal.push(res);
    }
    if (slot1[2].id === slot2[1].id && slot2[1].id === slot3[0].id) {
      win = true;
      diagonal.push(slot3[0].id);

      newLines.push({ type: "diagonal", index: 1 });

      let res = {
        slot1: {
          id: slot1[2].id,
          index: 2,
        },
        slot2: {
          id: slot2[1].id,
          index: 1,
        },
        slot3: {
          id: slot3[0].id,
          index: 0,
        },
      };

      countDiagonal.push(res);
    }

    if (rows) {
      resWin.push(...rows);
    }
    if (diagonal) {
      resWin.push(...diagonal);
    }

    setGameResult(resWin);

    setCountResultDiagonal(countDiagonal);

    setLines(newLines);
    return win;
  };

  const resetGame = () => {
    const newSlots = [];
    for (let i = 0; i < 3; i++) {
      newSlots.push(shuffleArray([...frames]).slice(0, 3));
    }
    setSlots(newSlots);
  };

  const handleSpin = () => {
    setHideLine(false);
    setCountResultLine([]);
    setCountResultDiagonal([]);

    const newSlots = [];
    newSlots.push(shuffleArray([...frames]).slice(0, 3));

    for (let i = 1; i < 3; i++) {
      let currentSlot;

      // С вероятностью increaseWinProbability делаем слоты совпадающими с предыдущими
      if (Math.random() < increaseWinProbability) {
        currentSlot = [...newSlots[i - 1]]; // Копируем предыдущий слот
        if (Math.random() < increaseWinProbability) {
          // Случайно заменяем одно изображение в слоте
          currentSlot[Math.floor(Math.random() * 3)] = shuffleArray([
            ...frames,
          ])[0];
        }
      } else {
        currentSlot = shuffleArray([...frames]).slice(0, 3); // Случайное заполнение
      }

      newSlots.push(currentSlot);
    }
    setSpinKeys((prevKeys) => prevKeys.map((key) => key + 1));

    setTimeout(() => {
      setSlots(newSlots);
    }, 1000);

    const result = {
      slot1: newSlots[0],
      slot2: newSlots[1],
      slot3: newSlots[2],
    };

    setTimeout(() => {
      if (checkWin(result)) {
        setFreezeButton(false);
      } else {
        const page = "gameOne";
        setTimeout(() => {
          playSound(lose);
          setFreezeButton(false);

          navigate(`/results/${0}/${page}`);
        }, 500);
      }
      setStartSpin(false);
      setHideLine(true);
    }, 1500);

    setActiveSlot(0);
  };

  useEffect(() => {
    if (startSpin) {
      handleSpin();
    }
  }, [startSpin]);

  useEffect(() => {
    if (Array.isArray(gameResult)) {
      let res = [];

      gameResult.forEach((item) => {
        let winPercentage = 0;

        switch (item) {
          case 1:
            winPercentage = 1.0;
            break;
          case 2:
            winPercentage = 1.5;
            break;
          case 3:
            winPercentage = 1.8;
            break;
          case 4:
            winPercentage = 2.0;
            break;
          case 5:
            winPercentage = 2.5;
            break;
          case 6:
            winPercentage = 3.0;
            break;
          case 7:
            winPercentage = 3.5;
            break;
          case 8:
            winPercentage = 4.0;
            break;
          case 9:
            winPercentage = 5.0;
            break;
          default:
            winPercentage = 0;
            break;
        }

        const winAmount = bet * winPercentage;
        res.push(winAmount);
      });

      const sumNumbers = res.reduce((a, b) => a + b, 0);

      setWinCounter(sumNumbers);

      const page = "gameOne";

      setTimeout(() => {
        if (sumNumbers > 0) {
          playSound(win);

          navigate(`/results/${sumNumbers}/${page}`);
        }
      }, 500);
    }
  }, [gameResult]);

  useEffect(() => {
    if (activeSlot >= 0 && activeSlot < slots.length) {
      const timer = setTimeout(() => {
        setActiveSlot((prev) => prev + 1);
      }, 200);
      return () => clearTimeout(timer);
    }
  }, [activeSlot]);

  const renderSlot = (slotIndex, slot) => {
    const animatedImages = Array.from(
      { length: 30 },
      (_, index) => slots[slotIndex][index % slots[slotIndex].length]
    );

    const getImageStyle = (slotIndex, indexInSlot) => {
      let showBack = false;

      // Проверяем все линии
      countResultLine.forEach((countLine) => {
        switch (slotIndex) {
          case 0:
            if (countLine.slot1 && countLine.slot1.index === indexInSlot) {
              showBack = true;
            }
            break;
          case 1:
            if (countLine.slot2 && countLine.slot2.index === indexInSlot) {
              showBack = true;
            }
            break;
          case 2:
            if (countLine.slot3 && countLine.slot3.index === indexInSlot) {
              showBack = true;
            }
            break;
          case 3:
            if (countLine.slot4 && countLine.slot4.index === indexInSlot) {
              showBack = true;
            }
            break;
          default:
            break;
        }
      });

      // Проверяем диагональные линии
      if (countResultDiagonal) {
        countResultDiagonal?.forEach((countDiagonal) => {
          switch (slotIndex) {
            case 0:
              if (
                countDiagonal.slot1 &&
                countDiagonal.slot1.index === indexInSlot
              ) {
                showBack = true;
              }
              break;
            case 1:
              if (
                countDiagonal.slot2 &&
                countDiagonal.slot2.index === indexInSlot
              ) {
                showBack = true;
              }
              break;
            case 2:
              if (
                countDiagonal.slot3 &&
                countDiagonal.slot3.index === indexInSlot
              ) {
                showBack = true;
              }
              break;
            case 3:
              if (
                countDiagonal.slot4 &&
                countDiagonal.slot4.index === indexInSlot
              ) {
                showBack = true;
              }
              break;
            default:
              break;
          }
        });
      }

      return { showBack };
    };

    const vh = window.innerHeight / 100;
    const animationHeight = -112 * vh;

    return (
      <div className={style.slotBlock} key={slotIndex}>
        <motion.div
          key={spinKeys[slotIndex]}
          className={style.slotsInner}
          initial={{ y: animationHeight }}
          animate={{ y: activeSlot >= slotIndex ? 0 : animationHeight }}
          transition={{ duration: 1, ease: "linear" }}
        >
          {animatedImages.map((image, index) => {
            const { showBack } = getImageStyle(slotIndex, index % slot.length);

            return (
              <div className={style.slotsItem} key={index}>
                {showBack ? (
                  <>
                    <img
                      src={image?.img}
                      alt={image?.alt}
                      className={style.slotsItemImage}
                    />
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ duration: 0.5 }}
                      style={{
                        position: "absolute",
                        top: -10,
                        left: 0,
                        right: 0,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src="/assets/lightTwo.png"
                        alt=""
                        style={{ width: "100%", height: "100%" }}
                        // className={style.lightSlotsImage}
                      />
                    </motion.div>
                  </>
                ) : (
                  <>
                    <img
                      src={image?.img}
                      alt={image?.alt}
                      className={style.slotsItemImage}
                    />
                  </>
                )}
              </div>
            );
          })}
        </motion.div>
      </div>
    );
  };

  return (
    <div className={style.slotsMachine}>
      {slots.map((slot, index) => renderSlot(index, slot))}
    </div>
  );
};

export default SlotMachine;
