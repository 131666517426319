import React from "react";
import { useDrag, useDrop } from "react-dnd";

const Block = ({ image, position, swapBlocks, isFalling, isFading }) => {
  const [{ isDragging }, dragRef] = useDrag({
    type: "BLOCK",
    item: { position },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, dropRef] = useDrop({
    accept: "BLOCK",
    drop: (item) => {
      swapBlocks(item.position, position);
    },
  });

  return (
    <div
      ref={(node) => dragRef(dropRef(node))}
      className={`block ${isFalling ? "falling" : ""} ${
        isFading ? "fade-out" : ""
      }`}
      style={{
        width: "35px",
        height: "35px", // Размер блока
        opacity: isDragging ? 0.5 : 1,
        backgroundImage: `url(${image})`,
        backgroundSize: "cover",
      }}
    />
  );
};

export default Block;
