import React, { useEffect, useState } from "react";
import { useSpring, animated } from "react-spring";
import style from "./gameTree.module.css";
import { useNavigate } from "react-router-dom";

const segments = [
  { label: "x2", id: 0 },
  { label: "200", id: 1 },
  { label: "5000", id: 2 },
  { label: "400", id: 3 },
  { label: "500", id: 4 },
  { label: "600", id: 5 },
  { label: "x1.5", id: 6 },
  { label: "800", id: 7 },
];

const segmentAngle = 360 / segments.length;
const initialOffsetAngle = 0;
const pointerOffsetAngle = 0;

const calculateSegment = (rotation) => {
  let adjustedRotation =
    (rotation + initialOffsetAngle + pointerOffsetAngle) % 360;
  if (adjustedRotation < 0) {
    adjustedRotation += 360;
  }

  const segmentIndex = Math.floor(adjustedRotation / segmentAngle);
  return segments[segmentIndex];
};

export default function AnimatedWheel({
  spinStart,
  setSpinStart,
  setSpinResult,
  setFreezeButton,
}) {
  const [totalRotation, setTotalRotation] = useState(0);

  const { rotate } = useSpring({
    rotate: totalRotation,
    config: { duration: 4500, easing: (t) => t * t * t },
    onRest: () => {
      const finalRotation = totalRotation % 360;
      const result = calculateSegment(Math.abs(finalRotation));
      setSpinStart(false);
      setSpinResult(result.label);
      setFreezeButton(false);
    },
  });

  const spin = () => {
    let fixedSpins = 3 + Math.random() * 2;
    fixedSpins = Math.round(fixedSpins * 8) / 8;
    const newTotalRotation = totalRotation - 360 * fixedSpins;

    setTotalRotation(newTotalRotation);
  };

  useEffect(() => {
    if (spinStart) {
      spin();
    }
  }, [spinStart]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <animated.div style={{ transform: rotate.to((r) => `rotate(${r}deg)`) }}>
        <img src="/assets/weel.png" alt="" className={style.imgWeelStyle} />
      </animated.div>
    </div>
  );
}
