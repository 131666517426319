import { useSelector } from "react-redux";
import { Howl } from "howler";
import { useEffect, useRef } from "react";

const useSound = () => {
  const effectsData = useSelector((state) => state.effects.effectsData);
  const musicRef = useRef(null);

  useEffect(() => {
    const loadData = async () => {
      const effects = await localStorage.getItem("effects");
      if (effects) {
        const data = JSON.parse(effects);
        const sound = parseFloat(data.sound?.percent) || 0.0;
        const music = parseFloat(data.music?.percent) || 0.0;

        if (musicRef.current) {
          musicRef.current.volume(music);
        }
      }
    };

    loadData();
  }, []);

  useEffect(() => {
    if (musicRef.current) {
      musicRef.current.volume(parseFloat(effectsData?.music?.percent) || 0.0);
    }
  }, [effectsData?.music?.percent]);

  const handleVisibilityChange = () => {
    if (document.hidden) {
      if (musicRef.current) {
        musicRef.current.pause(); // Приостановить музыку, если вкладка скрыта
      }
    } else {
      if (musicRef.current) {
        musicRef.current.play(); // Продолжить музыку, если вкладка снова видима
      }
    }
  };

  useEffect(() => {
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  const playMusic = (src) => {
    if (!musicRef.current) {
      musicRef.current = new Howl({
        src: [src],
        loop: true,
      });
    }
    musicRef.current.volume(parseFloat(effectsData?.music?.percent) || 0.0);
    musicRef.current.play();
  };

  const playSound = (src) => {
    try {
      const sound = new Howl({
        src: [src],
      });
      const volume = parseFloat(effectsData?.sound?.percent) || 0.0;
      sound.volume(volume);
      sound.play();
    } catch (error) {
      console.error("Error playing sound:", error);
    }
  };

  return { playMusic, playSound };
};

export default useSound;
