import React, { useEffect, useState } from "react";
import style from "./gameTwo.module.css";
import Navigate from "../../components/navigate";

import "./stylesForGame.css";

import { DndProvider } from "react-dnd";
import { TouchBackend } from "react-dnd-touch-backend";
import Grid from "./Grid";
import {
  applyGravity,
  checkMatches,
  fillEmptyCells,
  generateInitialGrid,
  removeMatches,
} from "./gameLogic";
import useSound from "../../utils/soundUtils";
import { useDispatch, useSelector } from "react-redux";

import bets from "../../songs/bet.mp3";
import win from "../../songs/win.mp3";
import lose from "../../songs/lose.mp3";

import spin from "../../songs/spin.mp3";

import { decrement, increment } from "../../store/counter/counter";
import { useNavigate } from "react-router-dom";

export default function GameTwo() {
  const [grid, setGrid] = useState(generateInitialGrid());
  const [score, setScore] = useState(0);

  const [movedAnyPosition, setMovedAnyPosition] = useState(null);

  const [startSpin, setStartSpin] = useState(false);
  const [winCount, setWinCounter] = useState(0);

  const [timerCount, setTimerCount] = useState(30);

  const [freezeButton, setFreezeButton] = useState(false);

  const [bet, setBet] = useState(50);

  const dispatch = useDispatch();

  const { playSound } = useSound();

  const navigate = useNavigate();

  const counter = useSelector((state) => state.counter.count);

  useEffect(() => {
    const handlePopState = (event) => {
      event.preventDefault();
      navigate(0); // Оставляет пользователя на текущей странице
    };

    window.history.pushState(null, "", window.location.href);
    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [navigate]);

  const plusBet = () => {
    playSound(bets);

    setBet((prev) => {
      const newBet =
        prev < counter ? (prev + 50 < counter ? prev + 50 : counter) : counter;
      return newBet;
    });
  };

  const minusBet = () => {
    playSound(bets);
    setBet((prev) => {
      const newBet = prev - 50;

      if (newBet < 0) {
        return 0;
      }

      return newBet;
    });
  };

  const goSpin = () => {
    if (bet > 0 && counter >= bet && !freezeButton) {
      playSound(spin);
      resetGame();
      setFreezeButton(true);
      dispatch(decrement(bet));
      setStartSpin(true);
    }
  };

  useEffect(() => {
    if (timerCount === 0) {
      setStartSpin(false);
      setFreezeButton(false);

      let page = "gameTwo";
      if (score > bet) {
        playSound(win);
        setWinCounter(score);
        setTimeout(() => {
          setTimerCount(30);

          navigate(`/results/${score}/${page}`);
        }, 500);
      } else {
        playSound(lose);
        setTimerCount(30);

        navigate(`/results/${0}/${page}`);
      }
    }
  }, [timerCount]);

  useEffect(() => {
    let interval;

    if (startSpin) {
      interval = setInterval(() => {
        setTimerCount((prev) => (prev > 1 ? prev - 1 : 0));
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [startSpin]);

  useEffect(() => {
    if (winCount > 0) {
      dispatch(increment(winCount));

      setTimeout(() => {
        setWinCounter(0);
      }, 1000);
    }
  }, [winCount]);

  const handleTurn = (updatedGrid) => {
    const processMatches = (grid, movedAnyPosition) => {
      let matchGroups = checkMatches(grid, movedAnyPosition);
      let totalPoints = 0;

      if (matchGroups.length > 0) {
        setTimeout(() => {
          let newGrid = removeMatches(grid, matchGroups.flat());
          newGrid = applyGravity(newGrid);
          newGrid = fillEmptyCells(newGrid);

          // Расчет очков
          matchGroups.forEach((group) => {
            const groupSize = group.length;
            if (groupSize >= 7) totalPoints += bet;
            else if (groupSize === 5) totalPoints += bet / 2;
            else if (groupSize === 3) totalPoints += bet / 3;
          });

          // Обнови сетку и очки
          setGrid(newGrid); // Обновление сетки
          highlightMatchedBlocks(newGrid, matchGroups.flat()); // Подсветка блоков
          setScore(
            (prevScore) => prevScore + parseFloat(totalPoints.toFixed(0))
          );

          // Проверь наличие новых совпадений и продолжай обработку, если нужно
          matchGroups = checkMatches(newGrid, movedAnyPosition);
          if (matchGroups.length > 0) {
            processMatches(newGrid, movedAnyPosition);
          }
        }, 500); // Задержка для видимости подсветки
      }

      return { grid, totalPoints };
    };

    if (checkMatches(grid, movedAnyPosition).length > 0) {
      processMatches(updatedGrid, movedAnyPosition);
    }
  };

  const highlightMatchedBlocks = (newGrid, matches) => {
    // Создаем копию сетки
    const updatedGrid = newGrid.map((row) => [...row]);

    // Удаление дубликатов
    const uniqueMatches = Array.from(
      new Set(matches.flat().map(JSON.stringify)),
      JSON.parse
    );

    uniqueMatches.forEach(({ row, col }) => {
      if (row !== undefined && col !== undefined) {
        if (updatedGrid[row] && updatedGrid[row][col] !== undefined) {
          updatedGrid[row][col] = "/assets/lightTwo.png";
        } else {
          console.error(`Invalid match position at row: ${row}, col: ${col}`);
        }
      } else {
        console.error("Invalid match object:", { row, col });
      }
    });

    // Обновление состояния сетки с подсвеченными блоками
    setGrid(updatedGrid);

    // Удаление подсветки после задержки
    setTimeout(() => {
      const clearedGrid = updatedGrid.map((row) =>
        row.map((cell) => (cell === "/assets/lightTwo.png" ? null : cell))
      );

      newGrid = applyGravity(clearedGrid);
      newGrid = fillEmptyCells(clearedGrid);

      setGrid(clearedGrid);
    }, 300); // Задержка, чтобы дать время на видимость подсветки
  };

  const resetGame = () => {
    setGrid(generateInitialGrid());
    setScore(0);
  };

  return (
    <div className={style.gameTwo}>
      <div className={style.timerBlock}>
        <div>
          <img src="/assets/timer.png" alt="" className={style.timerImg} />
        </div>
        <div className={style.timerTextBlock}>
          <span className={style.textTimer}>
            00:<span>{timerCount}</span>
          </span>
        </div>
      </div>
      <div className={style.gameTwoBlock}>
        <div className={style.navBlock}>
          <Navigate lastPage="gameTwo" startSpin={startSpin} />
        </div>
        <div className={style.gameBlock}>
          <div className={style.game}>
            <img
              src="/assets/frameForGames.png"
              alt=""
              className={style.imgFrameForGamesStyle}
            />
            <div className={style.gameMatchBlock}>
              <DndProvider backend={TouchBackend}>
                <Grid
                  grid={grid}
                  setGrid={setGrid}
                  handleTurn={handleTurn}
                  startSpin={startSpin}
                />
              </DndProvider>
            </div>
          </div>
        </div>
        <div className={style.navigateBlock}>
          <div className={style.countBlock}>
            <div className={style.countFrame}>
              <img
                src="/assets/frameForCount.png"
                alt=""
                className={style.imgFrameForCountStyle}
              />
              <div className={style.countDataBlock}>
                <div className={style.textBlock}>
                  <span className={style.textStyle}>BALANCE</span>
                  <span className={style.textStyleTwo}>{counter}</span>
                </div>
                <div className={style.textBlock}>
                  <span className={style.textStyle}>WIN</span>
                  <span className={style.textStyleTree}>{score}</span>
                </div>
              </div>
            </div>
          </div>
          <div className={style.spinBlock}>
            <img
              src="/assets/buttonSpin.png"
              alt=""
              className={style.imgButtonSpinStyle}
              onClick={goSpin}
            />
          </div>
          <div className={style.betBlock}>
            <div className={style.navBet}>
              <div>
                <img
                  src="/assets/buttonMinus.png"
                  alt=""
                  className={style.imgButtonNavStyle}
                  onClick={minusBet}
                />
              </div>
              <div>
                <img
                  src="/assets/buttonPlus.png"
                  alt=""
                  className={style.imgButtonNavStyle}
                  onClick={plusBet}
                />
              </div>
            </div>
            <div className={style.betFrame}>
              <img
                src="/assets/frameForBet.png"
                alt=""
                className={style.imgFrameForCountStyle}
              />
              <div className={style.textBetBlock}>
                <span className={style.textStyleFour}>{bet}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
