import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import store from "./store/store";

import "core-js/stable";
import "regenerator-runtime/runtime";

if (typeof queueMicrotask !== "function") {
  window.queueMicrotask = function (callback) {
    Promise.resolve().then(callback);
  };
}

const Root = () => (
  <Provider store={store}>
    <App />
  </Provider>
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>
);
