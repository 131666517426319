import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Intro from "./pages/intro/intro";
import Menu from "./pages/menu/menu";
import GameOne from "./pages/gameOne/gameOne";
import GameTwo from "./pages/gameTwo/gameTwo";
import Results from "./pages/results/results";
import Settings from "./pages/settings/settings";
import GameTree from "./pages/gameTree/gameTree";
import GameFour from "./pages/gameFour/gameFour";
import { useEffect, useState } from "react";
import useSound from "./utils/soundUtils";
import { useDispatch } from "react-redux";
import { getCount } from "./store/counter/counter";

import back from "./songs/back.mp3";

function App() {
  const [width, setWidth] = useState(null);
  const { playMusic } = useSound();

  const dispatch = useDispatch();

  useEffect(() => {
    initializeCount();
    playMusic(back);
    const screenWidth = window.innerWidth;
    setWidth(screenWidth);
  }, []);

  const initializeCount = async () => {
    try {
      const savedCount = await localStorage.getItem("countStore");
      if (savedCount !== null) {
        dispatch(getCount(JSON.parse(savedCount)));
      }
    } catch (error) {
      console.error("Error initializing count:", error);
    }
  };

  return (
    <div className="App">
      {width > 450 ? (
        <div className="bigScreen">
          <span className="bigScreenText">In process</span>
        </div>
      ) : (
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Intro />} />
            <Route path="/menu" element={<Menu />} />
            <Route path="/gameOne" element={<GameOne />} />
            <Route path="/gameTwo" element={<GameTwo />} />
            <Route path="/gameTree" element={<GameTree />} />
            <Route path="/gameFour" element={<GameFour />} />
            <Route path="/results/:count/:page" element={<Results />} />
            <Route path="/settings/:lastPage" element={<Settings />} />
          </Routes>
        </BrowserRouter>
      )}
    </div>
  );
}

export default App;
