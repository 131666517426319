import React, { useEffect, useState } from "react";
import style from "./gameOne.module.css";
import SlotMachine from "./slotMashine";
import { useDispatch, useSelector } from "react-redux";
import useSound from "../../utils/soundUtils";
import { decrement, increment } from "../../store/counter/counter";

import bets from "../../songs/bet.mp3";
import slot from "../../songs/slot.mp3";
import Navigate from "../../components/navigate";
import { useNavigate } from "react-router-dom";

export default function GameOne() {
  const [startSpin, setStartSpin] = useState(false);
  const [winCount, setWinCounter] = useState(0);

  const [freezeButton, setFreezeButton] = useState(false);

  const [bet, setBet] = useState(50);

  const dispatch = useDispatch();

  const { playSound } = useSound();

  const navigate = useNavigate();

  const counter = useSelector((state) => state.counter.count);

  useEffect(() => {
    const handlePopState = (event) => {
      event.preventDefault();
      navigate(0); // Оставляет пользователя на текущей странице
    };

    window.history.pushState(null, "", window.location.href);
    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [navigate]);

  const plusBet = () => {
    playSound(bets);

    setBet((prev) => {
      const newBet =
        prev < counter ? (prev + 50 < counter ? prev + 50 : counter) : counter;
      return newBet;
    });
  };

  const minusBet = () => {
    playSound(bets);
    setBet((prev) => {
      const newBet = prev - 50;

      if (newBet < 0) {
        return 0;
      }

      return newBet;
    });
  };

  const goSpin = () => {
    if (bet > 0 && counter >= bet && !freezeButton) {
      setFreezeButton(true);
      playSound(slot);
      dispatch(decrement(bet));
      setStartSpin(true);
    }
  };

  useEffect(() => {
    if (winCount > 0) {
      setTimeout(() => {
        dispatch(increment(winCount));
      }, 1500);
      setTimeout(() => {
        setWinCounter(0);
      }, 2000);
    }
  }, [winCount]);

  return (
    <div className={style.gameOne}>
      <div className={style.gameOneBlock}>
        <div className={style.navBlock}>
          <Navigate lastPage="gameOne" startSpin={startSpin} />
        </div>
        <div className={style.gameBlock}>
          <div className={style.game}>
            <img
              src="/assets/frameForGames.png"
              alt=""
              className={style.imgFrameForGamesStyle}
            />
            <div className={style.slotMachineBlock}>
              <SlotMachine
                startSpin={startSpin}
                setStartSpin={setStartSpin}
                bet={bet}
                setWinCounter={setWinCounter}
                setFreezeButton={setFreezeButton}
              />
            </div>
          </div>
        </div>
        <div className={style.navigateBlock}>
          <div className={style.countBlock}>
            <div className={style.countFrame}>
              <img
                src="/assets/frameForCount.png"
                alt=""
                className={style.imgFrameForCountStyle}
              />
              <div className={style.countDataBlock}>
                <div className={style.textBlock}>
                  <span className={style.textStyle}>BALANCE</span>
                  <span className={style.textStyleTwo}>{counter}</span>
                </div>
                <div className={style.textBlock}>
                  <span className={style.textStyle}>WIN</span>
                  <span className={style.textStyleTree}>{winCount}</span>
                </div>
              </div>
            </div>
          </div>
          <div className={style.spinBlock}>
            <img
              src="/assets/buttonSpin.png"
              alt=""
              className={style.imgButtonSpinStyle}
              onClick={goSpin}
            />
          </div>
          <div className={style.betBlock}>
            <div className={style.navBet}>
              <div>
                <img
                  src="/assets/buttonMinus.png"
                  alt=""
                  className={style.imgButtonNavStyle}
                  onClick={minusBet}
                />
              </div>
              <div>
                <img
                  src="/assets/buttonPlus.png"
                  alt=""
                  className={style.imgButtonNavStyle}
                  onClick={plusBet}
                />
              </div>
            </div>
            <div className={style.betFrame}>
              <img
                src="/assets/frameForBet.png"
                alt=""
                className={style.imgFrameForCountStyle}
              />
              <div className={style.textBetBlock}>
                <span className={style.textStyleFour}>{bet}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
