export const getRandomImage = () => {
  const colors = [
    "/assets/blue.png",
    "/assets/green.png",
    "/assets/pink.png",
    "/assets/purple.png",
    "/assets/orange.png",
  ];
  return colors[Math.floor(Math.random() * colors.length)];
};

// Проверка наличия совпадений (исключаем "any")
const hasThreeOrMoreInARow = (grid) => {
  // Проверка горизонтальных рядов
  for (let row = 0; row < grid.length; row++) {
    let count = 1;
    for (let col = 1; col < grid[row].length; col++) {
      if (
        grid[row][col] === grid[row][col - 1] &&
        grid[row][col] !== "/assets/any.png"
      ) {
        count++;
        if (count >= 3) return true;
      } else {
        count = 1;
      }
    }
  }

  // Проверка вертикальных колонок
  for (let col = 0; col < grid[0].length; col++) {
    let count = 1;
    for (let row = 1; row < grid.length; row++) {
      if (
        grid[row][col] === grid[row - 1][col] &&
        grid[row][col] !== "/assets/any.png"
      ) {
        count++;
        if (count >= 3) return true;
      } else {
        count = 1;
      }
    }
  }

  return false;
};

// Функция для генерации корректной начальной сетки (исключаем начальные совпадения)
// Функция для генерации корректной начальной сетки (без начальных совпадений)
export const generateInitialGrid = () => {
  let grid;
  do {
    grid = Array.from({ length: 8 }, () =>
      Array.from({ length: 8 }, () => getRandomImage())
    );
  } while (hasThreeOrMoreInARow(grid));

  // Добавляем ровно 5 блоков 'any' в случайные позиции
  let anyCount = 0;
  while (anyCount < 5) {
    const row = Math.floor(Math.random() * 8);
    const col = Math.floor(Math.random() * 8);

    // Проверяем, что в ячейке нет блока 'any'
    if (grid[row][col] !== "/assets/any.png") {
      grid[row][col] = "/assets/any.png";
      anyCount++;
    }
  }

  return grid;
};

// Проверка совпадений с учетом "any"
export const checkMatches = (grid, movedAnyPosition) => {
  const numRows = grid.length;
  const numCols = grid[0].length;
  let matches = [];

  const isMovedAny = (row, col) => {
    return (
      movedAnyPosition &&
      movedAnyPosition.row === row &&
      movedAnyPosition.col === col
    );
  };

  const isSameOrAny = (image1, image2, row, col) => {
    if (isMovedAny(row, col)) {
      return true;
    }
    return image1 === image2 || image2 === "/assets/any.png";
  };

  const addMatchesIfNeeded = (match) => {
    if (match.length >= 3) {
      matches.push(match);
    }
  };

  // Проверка горизонтальных матчей
  for (let row = 0; row < numRows; row++) {
    let match = [];
    for (let col = 0; col < numCols; col++) {
      const currentImage = grid[row][col];
      const prevImage = grid[row][col - 1];

      if (currentImage === null) {
        addMatchesIfNeeded(match);
        match = [];
        continue;
      }

      if (col === 0 || !isSameOrAny(prevImage, currentImage, row, col)) {
        addMatchesIfNeeded(match);
        match = [{ row, col }];
      } else {
        match.push({ row, col });
      }
    }
    addMatchesIfNeeded(match);
  }

  // Проверка вертикальных матчей
  for (let col = 0; col < numCols; col++) {
    let match = [];
    for (let row = 0; row < numRows; row++) {
      const currentImage = grid[row][col];
      const prevImage = grid[row - 1]?.[col];

      if (currentImage === null) {
        addMatchesIfNeeded(match);
        match = [];
        continue;
      }

      if (row === 0 || !isSameOrAny(prevImage, currentImage, row, col)) {
        addMatchesIfNeeded(match);
        match = [{ row, col }];
      } else {
        match.push({ row, col });
      }
    }
    addMatchesIfNeeded(match);
  }

  return matches;
};

export const removeMatches = (grid, matches) => {
  matches.forEach(({ row, col }) => {
    grid[row][col] = null; // Удаляем блоки, помечая их как пустые
  });
  return grid;
};

export const applyGravity = (grid, setFallingBlocks) => {
  const newGrid = JSON.parse(JSON.stringify(grid));

  const blocksToAnimate = [];

  // Проходим по каждому столбцу
  for (let col = 0; col < newGrid[0].length; col++) {
    let emptyRow = null; // Индекс пустой строки

    // Проходим по каждой строке, начиная с нижней
    for (let row = newGrid.length - 1; row >= 0; row--) {
      if (newGrid[row][col] === null && emptyRow === null) {
        emptyRow = row;
      } else if (newGrid[row][col] !== null && emptyRow !== null) {
        newGrid[emptyRow][col] = newGrid[row][col];
        newGrid[row][col] = null;

        blocksToAnimate.push({ row: emptyRow, col });

        emptyRow--;
      }
    }
  }

  if (typeof setFallingBlocks === "function") {
    setFallingBlocks(blocksToAnimate);
  } else {
    console.error("setFallingBlocks is not a function");
  }

  return newGrid;
};

export const fillEmptyCells = (grid) => {
  const numRows = grid.length;
  const numCols = grid[0].length;

  for (let col = 0; col < numCols; col++) {
    // Проходим по колонке снизу вверх и сдвигаем блоки вниз
    for (let row = numRows - 1; row >= 0; row--) {
      if (grid[row][col] === null) {
        // Найти ближайший непустой блок выше
        for (let searchRow = row - 1; searchRow >= 0; searchRow--) {
          if (grid[searchRow][col] !== null) {
            // Перемещаем блок вниз
            grid[row][col] = grid[searchRow][col];
            grid[searchRow][col] = null;
            break;
          }
        }
      }
    }
  }

  return grid; // Возвращаем обновленную сетку
};
