// counterSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  count: 1000,
};

const counterSlice = createSlice({
  name: "counter",
  initialState,
  reducers: {
    increment: (state, action) => {
      state.count += action.payload;
      localStorage.setItem("countStore", state.count.toString());
    },
    decrement: (state, action) => {
      state.count -= action.payload;
      localStorage.setItem("countStore", state.count.toString());
    },
    getCount: (state, action) => {
      state.count = action.payload;
    },
    resetCount: (state) => {
      state.count = 1000;
      localStorage.setItem("countStore", state.count.toString());
    },
  },
});

export const { increment, decrement, getCount, resetCount } =
  counterSlice.actions;
export default counterSlice.reducer;
export { counterSlice };
