import React from "react";
import style from "./navigate.module.css";

import press from "../songs/onpress.mp3";

import useSound from "../utils/soundUtils";
import { useNavigate } from "react-router-dom";

export default function Navigate({ lastPage, startSpin }) {
  const navigate = useNavigate();

  const { playSound } = useSound();

  const backPage = () => {
    if (!startSpin) {
      playSound(press);
      const timer = setTimeout(() => {
        navigate("/menu");
      }, 500);

      return () => clearTimeout(timer);
    }
  };

  const settingsPage = () => {
    if (!startSpin) {
      playSound(press);
      const timer = setTimeout(() => {
        navigate(`/settings/${lastPage}`);
      }, 500);

      return () => clearTimeout(timer);
    }
  };

  return (
    <div className={style.navBlock}>
      <div>
        <img
          src="/assets/buttonBack.png"
          alt=""
          className={style.imgNavStyle}
          onClick={backPage}
        />
      </div>
      <div>
        <img
          src="/assets/buttonSettings.png"
          alt=""
          className={style.imgNavStyle}
          onClick={settingsPage}
        />
      </div>
    </div>
  );
}
