import React from "react";
import style from "./results.module.css";
import { useNavigate, useParams } from "react-router-dom";
import useSound from "../../utils/soundUtils";

import press from "../../songs/onpress.mp3";

export default function Results() {
  const { count, page } = useParams();

  const { playSound } = useSound();

  const navigate = useNavigate();

  const backPage = () => {
    playSound(press);
    navigate(`/${page}`);
  };

  return (
    <div className={style.results}>
      <div className={style.resultsBlock}>
        <div className={style.gameBlock}>
          <div className={style.game}>
            <img
              src="/assets/frameForGames.png"
              alt=""
              className={style.imgFrameForGamesStyle}
            />
            {count > 0 && (
              <div className={style.lightBlock}>
                <img
                  src="/assets/light.png"
                  alt=""
                  className={style.imgLightStyle}
                />
              </div>
            )}

            <div className={style.resultBlock}>
              <div className={style.textBlock}>
                <span className={style.textOneStyle}>
                  {count > 0 ? "WIN" : "LOSE"}
                </span>
                <span className={style.textTwoStyle}>{count}</span>
              </div>
              <div className={style.fullBoxBlock}>
                {count > 0 ? (
                  <img
                    src="/assets/fullBox.png"
                    alt=""
                    className={style.imgLightStyle}
                  />
                ) : (
                  <img
                    src="/assets/emptyBox.png"
                    alt=""
                    className={style.imgLightStyle}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={style.buttonBlock}>
          <img
            src="/assets/buttonContinue.png"
            alt=""
            className={style.imgButtonStyle}
            onClick={backPage}
          />
        </div>
      </div>
    </div>
  );
}
