import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  effectsData: {
    music: {
      percent: 0.1,
    },
    sound: {
      percent: 0.1,
    },
  },
};

export const effectsSlice = createSlice({
  name: "effects",
  initialState,
  reducers: {
    getEffectData: (state, action) => {
      state.effectsData = action.payload;
    },
  },
});

export const { getEffectData } = effectsSlice.actions;

export default effectsSlice.reducer;
