import React, { useState } from "react";
import style from "./menu.module.css";
import { useNavigate } from "react-router-dom";
import useSound from "../../utils/soundUtils";

import press from "../../songs/onpress.mp3";
import choisen from "../../songs/switchsound.mp3";

export default function Menu() {
  const [choisenGame, setChoisenGame] = useState("");

  const navigate = useNavigate();

  const { playSound } = useSound();

  const backPage = () => {
    playSound(press);
    const timer = setTimeout(() => {
      navigate("/");
    }, 500);

    return () => clearTimeout(timer);
  };

  const pageSettings = () => {
    playSound(press);
    const lastPage = "menu";
    const timer = setTimeout(() => {
      navigate(`/settings/${lastPage}`);
    }, 500);

    return () => clearTimeout(timer);
  };

  const pageGame = () => {
    if (choisenGame != "") {
      playSound(press);
      const timer = setTimeout(() => {
        navigate(`/${choisenGame}`);
      }, 500);

      return () => clearTimeout(timer);
    }
  };

  const choiseGame = (game) => {
    playSound(choisen);

    setChoisenGame(game);
  };

  return (
    <div className={style.menu}>
      <div className={style.menuBlock}>
        <div className={style.navBlock}>
          <div>
            <img
              src="/assets/buttonBack.png"
              alt=""
              className={style.imgNavStyle}
              onClick={backPage}
            />
          </div>
          <div>
            <img
              src="/assets/buttonSettings.png"
              alt=""
              className={style.imgNavStyle}
              onClick={pageSettings}
            />
          </div>
        </div>
        <div className={style.gamesBlock}>
          <div className={style.roadBlock}>
            <img
              src="/assets/roadGames.png"
              alt=""
              className={style.imgRoadStyle}
            />
          </div>
          <div className={style.gameFourBlock}>
            <img
              src="/assets/menuGameFour.png"
              alt=""
              className={style.imgGameFourStyle}
              onClick={() => choiseGame("gameFour")}
            />
          </div>
          <div className={style.gameTreeBlock}>
            <img
              src="/assets/menuGameTree.png"
              alt=""
              className={style.imgGameTreeStyle}
              onClick={() => choiseGame("gameTree")}
            />
          </div>

          <div className={style.gameTwoBlock}>
            <img
              src="/assets/menuGameTwo.png"
              alt=""
              className={style.imgGameTwoStyle}
              onClick={() => choiseGame("gameTwo")}
            />
          </div>

          <div className={style.gameOneBlock}>
            <img
              src="/assets/menuGameOne.png"
              alt=""
              className={style.imgGameOneStyle}
              onClick={() => choiseGame("gameOne")}
            />
          </div>
        </div>
        <div className={style.buttonBlock}>
          <img
            src="/assets/buttonContinue.png"
            alt=""
            className={style.imgButtonStyle}
            onClick={pageGame}
          />
        </div>
      </div>
    </div>
  );
}
