import React, { useEffect, useState } from "react";
import style from "./settings.module.css";

import { useSpring, animated } from "react-spring";
import { useDrag } from "react-use-gesture";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useSound from "../../utils/soundUtils";
import { getEffectData } from "../../store/sounds/sounds";
import { resetCount } from "../../store/counter/counter";

import reset from "../../songs/resetscore.mp3";
import press from "../../songs/onpress.mp3";

export default function Settings() {
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
  const [popupResetScore, setPopupResetScore] = useState(false);

  const effectsData = useSelector((state) => state.effects.effectsData);

  const { lastPage } = useParams();

  const { playSound } = useSound();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    const loadData = async () => {
      const effects = await localStorage.getItem("effects");
      if (effects) {
        const data = JSON.parse(effects);

        let musicWV = volumeToPosition(data.music?.percent);
        let soundVW = volumeToPosition(data.sound?.percent);
        let sound = parseFloat(soundVW);
        let music = parseFloat(musicWV);

        set1({ x: sound });
        set2({ x: music });
      }
      const handleResize = () => setViewportWidth(window.innerWidth);
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    };

    loadData();
  }, []);

  const vwToPx = (vw) => (vw / 100) * viewportWidth;

  const maxVw = 59; // 60vw
  const minVw = 3; // 0vw

  // Позиции в пикселях
  const maxPos = vwToPx(maxVw);
  const minPos = vwToPx(minVw);

  const [{ x: x1 }, set1] = useSpring(() => ({ x: vwToPx(0) }));
  const [{ x: x2 }, set2] = useSpring(() => ({ x: vwToPx(0) }));

  const volumeToVw = (volume) => {
    // Ограничиваем volume в диапазоне от 0 до 1
    const clampedVolume = Math.min(Math.max(volume, 0), 1);

    // Преобразуем volume в значение vw
    const vw = clampedVolume * (maxVw - minVw) + minVw;

    return parseFloat(vw.toFixed(2)); // Возвращаем значение, округленное до двух знаков после запятой
  };

  // Преобразование позиции в объем (volume)
  const positionToVolume = (position) => {
    const volume = (position - minPos) / (maxPos - minPos);
    return Math.min(Math.max(volume, 0), 1).toFixed(2);
  };

  const volumeToPosition = (volume) => {
    // Преобразуем volume в vw
    const vw = volumeToVw(volume);

    // Преобразуем vw в пиксели
    const positionPx = vwToPx(vw);

    return positionPx;
  };

  const bindOne = useDrag(({ movement: [mx], memo = x1.get() }) => {
    let newPos = memo + mx;
    if (newPos < minPos) newPos = minPos;
    if (newPos > maxPos) newPos = maxPos;
    set1({ x: newPos });

    console.log("before", newPos);

    const volume = positionToVolume(newPos);

    console.log("before volume", volume);

    switchEffects({
      sound: { percent: volume },
      music: { percent: effectsData?.music?.percent },
    });

    dispatch(
      getEffectData({
        sound: { percent: volume },
        music: { percent: effectsData?.music?.percent },
      })
    );

    return memo;
  });

  const bindTwo = useDrag(({ movement: [mx], memo = x2.get() }) => {
    let newPos = memo + mx;
    if (newPos < minPos) newPos = minPos;
    if (newPos > maxPos) newPos = maxPos;
    set2({ x: newPos });

    const volume = positionToVolume(newPos);

    switchEffects({
      sound: { percent: effectsData?.sound?.percent },
      music: { percent: volume },
    });

    dispatch(
      getEffectData({
        sound: { percent: effectsData?.sound?.percent },
        music: { percent: volume },
      })
    );

    return memo;
  });

  const switchEffects = async (data) => {
    let effects = JSON.stringify(data);
    try {
      await localStorage.setItem("effects", effects);
    } catch (error) {
      console.error(error);
    }
  };

  const resetScore = () => {
    playSound(reset);
    dispatch(resetCount());
    localStorage.removeItem("countStore");
    setPopupResetScore(true);
    setTimeout(() => {
      setPopupResetScore(false);
    }, 2000);
  };

  const backPage = () => {
    playSound(press);
    const timer = setTimeout(() => {
      navigate(`/${lastPage}`);
    }, 500);

    return () => clearTimeout(timer);
  };

  return (
    <div className={style.settings}>
      <div
        className={`${style.popupResScore} ${
          popupResetScore ? style.active : ""
        }`}
      >
        <div className={style.popupResScoreBlock}>
          <span className={style.textPopup}>Clean score!</span>
        </div>
      </div>
      <div className={style.settingsBlock}>
        <div className={style.navBlock}>
          <div>
            <img
              src="/assets/buttonBack.png"
              alt=""
              className={style.imgNavStyle}
              onClick={backPage}
            />
          </div>
          <div></div>
        </div>
        <div className={style.gameBlock}>
          <div className={style.game}>
            <img
              src="/assets/frameForGames.png"
              alt=""
              className={style.imgFrameForGamesStyle}
            />
            <div className={style.switchBlock}>
              <div className={style.settingsText}>
                <span className={style.textOneStyle}>Settings</span>
              </div>
              <div className={style.switchSoundBlock}>
                <div className={style.textBlock}>
                  <span className={style.textTwoStyle}>Sound</span>
                </div>
                <div className={style.switchComponent}>
                  <div className={style.switchSoundBack}>
                    <animated.div
                      style={{
                        position: "absolute",
                        top: 0,
                        bottom: 0,
                        left: 0,
                        width: x1.to((x) => `${x}px`),
                        height: "3.5vh",
                        backgroundColor: " #fb983e",
                        borderRadius: "2.5vw",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    />
                    <animated.div
                      {...bindOne()}
                      style={{
                        position: "absolute",
                        left: x1.to((x) => `${x - vwToPx(4)}px`),
                        touchAction: "none",
                        zIndex: 30,
                        top: 0,
                        bottom: 0,
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src="/assets/roudOne.png"
                        alt=""
                        className={style.imageScroll}
                      />
                    </animated.div>
                  </div>
                </div>
              </div>

              <div className={style.switchSoundBlock}>
                <div className={style.textBlock}>
                  <span className={style.textTwoStyle}>Music</span>
                </div>
                <div className={style.switchComponent}>
                  <div className={style.switchMusicBack}>
                    <animated.div
                      style={{
                        position: "absolute",
                        top: 0,
                        bottom: 0,
                        left: 0,
                        width: x2.to((x) => `${x}px`),
                        height: "3.5vh",
                        backgroundColor: " #1D8488",
                        borderRadius: "2.5vw",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    />
                    <animated.div
                      {...bindTwo()}
                      style={{
                        position: "absolute",
                        left: x2.to((x) => `${x - vwToPx(4)}px`),
                        touchAction: "none",
                        zIndex: 30,
                        top: 0,
                        bottom: 0,
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src="/assets/roudTwo.png"
                        alt=""
                        className={style.imageScroll}
                      />
                    </animated.div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={style.buttonBlock}>
          <img
            src="/assets/buttonReset.png"
            alt=""
            className={style.imgButtonStyle}
            onClick={resetScore}
          />
        </div>
      </div>
    </div>
  );
}
