import React, { useState, useEffect } from "react";
import Block from "./Block";
import { applyGravity, checkMatches } from "./gameLogic";
import useSound from "../../utils/soundUtils";

import match from "../../songs/switchsound.mp3";

const Grid = ({ grid, setGrid, handleTurn, startSpin }) => {
  const [movedAnyPosition, setMovedAnyPosition] = useState(null);
  const [fallingBlocks, setFallingBlocks] = useState([]);

  const { playSound } = useSound();

  // Проверка соседства блоков
  const isAdjacent = (block1, block2) => {
    const rowDiff = Math.abs(block1.row - block2.row);
    const colDiff = Math.abs(block1.col - block2.col);
    return (rowDiff === 1 && colDiff === 0) || (rowDiff === 0 && colDiff === 1);
  };

  // Функция для обмена местами двух блоков
  const swapBlocks = (block1, block2) => {
    if (startSpin) {
      const block1Image = grid[block1.row][block1.col];
      const block2Image = grid[block2.row][block2.col];

      if (!block1Image || !block2Image) {
        console.log("Невозможно переместить блок в пустую ячейку!");
        return;
      }

      if (isAdjacent(block1, block2)) {
        const newGrid = [...grid];

        let movedAny = false;
        if (block1Image === "/assets/any.png") {
          setMovedAnyPosition({ row: block2.row, col: block2.col });
          movedAny = true;
        } else if (block2Image === "/assets/any.png") {
          setMovedAnyPosition({ row: block1.row, col: block1.col });
          movedAny = true;
        } else {
          setMovedAnyPosition(null);
        }

        newGrid[block1.row][block1.col] = block2Image;
        newGrid[block2.row][block2.col] = block1Image;

        if (movedAny) {
          checkAndConvertAnyBlock();
        }

        const matchGroups = checkMatches(newGrid);

        if (matchGroups.length > 0) {
          playSound(match);
          handleTurn(newGrid);
        } else {
          console.log("Нет совпадений, обмен отменен.");
        }
      } else {
        console.log("Блоки не являются соседями!");
      }
    }
  };

  // Логика для преобразования "any.png" в цвет соседних блоков
  const checkAndConvertAnyBlock = () => {
    if (movedAnyPosition) {
      const { row, col } = movedAnyPosition;

      const neighbors = getNeighbors(row, col);
      const matchingNeighborColor = findMatchingColor(neighbors);

      if (matchingNeighborColor) {
        const newGrid = [...grid];
        if (newGrid[row][col] !== null) {
          newGrid[row][col] = matchingNeighborColor;
          setGrid(newGrid);
        }
      }
    }
  };

  // Функция для получения соседей блока
  const getNeighbors = (row, col) => {
    const neighbors = [];

    if (row > 0) neighbors.push(grid[row - 1][col]); // сверху
    if (row < grid.length - 1) neighbors.push(grid[row + 1][col]); // снизу
    if (col > 0) neighbors.push(grid[row][col - 1]); // слева
    if (col < grid[0].length - 1) neighbors.push(grid[row][col + 1]); // справа

    return neighbors;
  };

  // Поиск цвета, который встречается дважды среди соседей
  const findMatchingColor = (neighbors) => {
    const colorCount = {};
    neighbors.forEach((neighbor) => {
      if (neighbor && neighbor !== "/assets/any.png") {
        colorCount[neighbor] = (colorCount[neighbor] || 0) + 1;
      }
    });
    for (let color in colorCount) {
      if (colorCount[color] >= 2) {
        return color;
      }
    }
    return null;
  };

  useEffect(() => {
    checkAndConvertAnyBlock();
  }, [movedAnyPosition]);

  useEffect(() => {
    if (fallingBlocks.length > 0) {
      const timer = setTimeout(() => {
        const newGrid = applyGravity(grid, setFallingBlocks);
        setGrid(newGrid);
        handleTurn(newGrid);
      }, 500);

      return () => clearTimeout(timer);
    }
  }, [fallingBlocks, grid, setGrid, handleTurn]);

  return (
    <div className="grid">
      {grid.map((row, rowIndex) => (
        <div key={rowIndex} className="row">
          {row.map((image, colIndex) => (
            <div key={colIndex} className="block">
              <Block
                image={image}
                position={{ row: rowIndex, col: colIndex }}
                swapBlocks={swapBlocks}
                isFalling={fallingBlocks.some(
                  (block) => block.row === rowIndex && block.col === colIndex
                )}
              />
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default Grid;
