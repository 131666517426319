import { style } from "framer-motion/client";
import React, { useState } from "react";
import { useSpring, animated } from "react-spring";
import styled from "styled-components";

import styles from "./flippableCard.module.css";

import open from "../../songs/opencard.mp3";
import useSound from "../../utils/soundUtils";

const Card = styled(animated.div)`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  backface-visibility: hidden;
`;

const Front = styled(Card)`
  background: url("/path-to-front-image.png") no-repeat center/cover;
`;

const Back = styled(Card)`
  background: url("/path-to-back-image.png") no-repeat center/cover;
  transform: rotateY(180deg);
`;

export default function FlippableCard({
  frontImage,
  backImage,
  startGame,
  setImageId,
  setStartGame,
  id,
}) {
  const [flipped, setFlipped] = useState(false);

  const { playSound } = useSound();

  const { transform, zIndex, opacity } = useSpring({
    transform: flipped ? "rotateY(180deg)" : "rotateY(0deg)",
    zIndex: flipped ? 1 : 0,
    opacity: flipped ? 1 : 0,
    config: { mass: 5, tension: 500, friction: 80 },
  });

  const handleCardClick = () => {
    if (startGame) {
      playSound(open);
      setFlipped(!flipped);
      setImageId(id);
      setStartGame(false);
    }
  };

  return (
    <div className={styles.cardComponent} onClick={handleCardClick}>
      <Back
        style={{
          transform,
          zIndex: zIndex.to((z) => (flipped ? z : 1)),
          opacity: opacity.to((o) => 1 - o),
          backgroundImage: `url(${backImage})`,
        }}
      />
      <Front
        style={{
          transform: transform.to((t) => `${t} rotateY(180deg)`),
          zIndex: zIndex.to((z) => (flipped ? 1 : z)),
          opacity,
          backgroundImage: `url(${frontImage})`,
        }}
      />
    </div>
  );
}
